<template>
<div class=" max-h-full overflow-y-auto flex flex-col h-full w-full mt-6">
  <div class="text-white w-full absolute top-0 z-0 flex justify-center">
    <span v-text="(this.$store.state.box_project.length>0)?(this.$store.state.index!=undefined)?this.$store.state.box_project[ this.$store.state.index][0]:'':''"> </span>
  </div>

  <div :class="this.$store.state.box_project[this.$store.state.index]?this.$store.state.box_project[this.$store.state.index][1]?this.$store.state.box_project[this.$store.state.index][1].length == total_faite() && this.$store.state.box_project[this.$store.state.index][1].length>0?'flex':'hidden':'':''" class="flex bg-black  flex-col absolute justify-center items-center top-0 left-0 w-full h-full" >
    <svg xmlns="http://www.w3.org/2000/svg" height="64px" viewBox="0 0 24 24" width="64px" fill="#30F090"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>
    <span class="text-green-500 text-base">Les taches sont achevés</span>
  </div>

  <div class=" flex w-full h-full duration-500 flex-col-reverse justify-end px-2"  v-if="this.$store.state.box_project.length>0 && this.$store.state.index!=undefined">
    <div class="flex flex-col" v-if="this.$store.state.box_project_temp.length==0">
          <div  v-for="taches,i in this.$store.state.box_project[this.$store.state.index][1]" 
          :key="i" :id="'taches'+i"
          class=" duration-500 flex cursor-pointer flex-row justify-between items-center rounded-l-lg mt-3 "
          :class="taches[1]==false?'':'bg-red-600 '"
          @mousedown="start_chrono(taches)"
          @mouseup="stop_chrono()"
          >
          
          <div @click="reset_remove(taches)" v-if="taches[1]==true" class="absolute cursor-default w-full h-full top-0 left-0 z-30 "></div>
            <div
            @click="chrono==0?finish_task(i):''"
            :style="this.$store.state.list_doing[this.$store.state.index][i]==false?'border:1px solid rgba(255,255,255,.1);  background:#009b7c;':' background:black; border:1px solid rgba(255,255,255,.1);'"
            class="py-1 flex justify-between flex-row h-full rounded-lg w-full" 
            :class="this.$store.state.list_doing[this.$store.state.index][i]==this.$store.state.faites?'flex ':'hidden'">
              <div class="flex flex-col h-full justify-between px-1">
                <span id="point"></span>
                <span id="point"></span>
              </div>
              <div class="w-full flex  break-all">
                <span class="text-white mr-4">{{i+1}}</span>
                <span :class="this.$store.state.list_doing[this.$store.state.index][i]==true?' opacity-30':''" class=" text-left text-white break-words">{{taches[0]}}</span>
              </div>
              <div class="flex flex-col h-full justify-between px-1">
                <span id="point"></span>
                <span id="point"></span>
              </div>

              <div v-if="this.$store.state.list_doing[this.$store.state.index][i]==true" class=" relative ">
                <div class=" -ml-5 -mt-3 absolute flex justify-center px-0 bg-green-500 rounded-full z-50">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                </div>
              </div>
              
            </div>
            
            <div 
            v-if="taches[1]==true"
            class="flex px-3 bg-red-600 h-full duration-500 z-40 items-center" 
            :class="this.$store.state.list_doing[this.$store.state.index][i]==this.$store.state.faites?'flex ':'hidden'"
            >
             <svg @click="get_titire(taches[0],i)" class=" mr-2 animate-bounce" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
              <svg @click="remove_it(i)" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
            </div>
          </div>
    </div>







      <div class="flex flex-col" v-if="this.$store.state.box_project_temp.length>0">

          <div  v-for="taches,i in  this.$store.state.box_project_temp " 
          :key="i" :id="'taches'+i"
          class=" duration-500 flex cursor-pointer flex-row justify-between items-center rounded-l-lg mt-3 "
          :class="taches[1]==false?'':'bg-red-600 ' "
          @mousedown="start_chrono(taches)"
          @mouseup="stop_chrono()"
          >
            <div @click="reset_remove(taches)" v-if="taches[1]==true" class="absolute cursor-default w-full h-full top-0 left-0 z-30 "></div>
            <div
            @click="chrono==0?finish_task(i):''"
            :style="this.$store.state.list_doing[this.$store.state.index][i]==false?'border:1px solid rgba(255,255,255,.1);  background:#009b7c;':' background:black; border:1px solid rgba(255,255,255,.1);'"
            class="py-1 flex justify-between flex-row h-full rounded-lg w-full" 
            :class="this.$store.state.list_doing[this.$store.state.index][i]==this.$store.state.faites?'flex ':'hidden'">
              <div class="flex flex-col h-full justify-between px-1">
                <span id="point"></span>
                <span id="point"></span>
              </div>
              <div class="w-full flex  break-all">
                <span class="text-white mr-4">{{i+1}}</span>
                <span :class="this.$store.state.list_doing[this.$store.state.index][i]==true?' opacity-30':''" class=" text-white break-words">{{taches[0]}}</span>
              </div>
              <div class="flex flex-col h-full justify-between px-1">
                <span id="point"></span>
                <span id="point"></span>
              </div>

              <div v-if="this.$store.state.list_doing[this.$store.state.index][i]==true" class=" relative ">
                <div class=" -ml-5 -mt-3 absolute flex justify-center px-0 bg-green-500 rounded-full z-50">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                </div>
              </div>
              
            </div>
            
            <div 
            v-if="taches[1]==true"
            @click="remove_it(i)"
            class="flex px-3 bg-red-600 h-full duration-500 z-40 items-center" 
            :class="this.$store.state.list_doing[this.$store.state.index][i]==this.$store.state.faites?'flex ':'hidden'"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
            </div>
          </div>
      </div>

      <div  v-if="this.$store.state.box_project[this.$store.state.index][1].length<1" class="flex top-0 left-0 absolute w-full h-full justify-center items-center flex-col z-0">
        <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#fff"><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>
        <span class="text-white">La liste est vide</span>
      </div>
  </div>
</div>
</template>

<script>
export default {
  data(){
    return{
      pourcentage:0,
      remove:false,
      chrono:0,
      timer:'',
      block:false,
      faite:0,
      pas_faite:0

    }
  },
  methods:{
    finish_task(i){
      this.select_task(i)

      this.pourcentage=this.percentage(this.$store.state.box_project[this.$store.state.index][1])
      if(this.pourcentage==100){
        document.querySelector('#inside_progress').style.borderRadius='50px 50px 50px 50px'  
      }
      else{
        document.querySelector('#inside_progress').style.borderRadius='50px 0px 0px 50px'  
      }

      document.querySelector('#inside_progress').style.width=this.pourcentage+'%'
      this.$store.state.adding=true
    },
    percentage(somme){
      return this.total_faite()*100/somme.length
    },
    select_task(i){
      if(this.$store.state.list_doing[this.$store.state.index][i]==true){
        this.$store.state.list_doing[this.$store.state.index][i]=false
      }else{
        this.$store.state.list_doing[this.$store.state.index][i]=true
      }
      localStorage.setItem('0_0',JSON.stringify(this.$store.state.box_project))
      localStorage.setItem('1_1',JSON.stringify(this.$store.state.list_doing))

    },

    deselect_task(i){
      var array=[]
      for (let k = 0; k < this.$store.state.list_doing[this.$store.state.index].length; k++) {
        const element = this.$store.state.list_doing[this.$store.state.index][k];
        if(element==i ){
          this.$store.state.list_doing[this.$store.state.index][k]=''
        }
      }
      for (let l = 0; l < this.$store.state.list_doing[this.$store.state.index].length; l++) {
        const element = this.$store.state.list_doing[this.$store.state.index][l]+'';
        if(element!=''){
          array.push(parseInt(element))
        }
      }
      this.$store.state.list_doing[this.$store.state.index]=array
      localStorage.setItem('0_0',JSON.stringify(this.$store.state.box_project))
      localStorage.setItem('1_1',JSON.stringify(this.$store.state.list_doing))
    },

    total_faite(){
      var array= this.$store.state.list_doing[this.$store.state.index]
      var total=0
      if(array){

      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if(element==true){
          total++
        }
      }
      }
      return total
    },
    start_chrono(e){
      this.timer=setInterval(() => {
        this.chrono++
        if(this.chrono>=1){
          this.$store.state.box_project[this.$store.state.index][1]
          e[1]=true
          clearInterval(this.timer)
          this.block=true
        }
      }, 1000);
    },
    reset_remove(e){
      e[1]=false
      clearInterval(this.timer)
      this.chrono=0
    },
    stop_chrono(){
      clearInterval(this.timer)
      this.chrono=0
    },
    remove_it(i){
      var array=[]
      this.$store.state.box_project[this.$store.state.index][1][i]=''
      
      for (let h = 0; h < this.$store.state.box_project[this.$store.state.index][1].length; h++) {
        const element = this.$store.state.box_project[this.$store.state.index][1][h];
        if(element!=''){
          array.push(element)
        }
      }
      this.$store.state.box_project[this.$store.state.index][1]=array

      var array3=[]
      this.$store.state.list_doing[this.$store.state.index][i]='k'

      for (let index = 0; index < this.$store.state.list_doing[this.$store.state.index].length; index++) {
        const element = this.$store.state.list_doing[this.$store.state.index][index];
        if(element!='k'){
          array3.push(element)
        }
      }
      this.$store.state.list_doing[this.$store.state.index]=array3


      localStorage.setItem('0_0',JSON.stringify(this.$store.state.box_project))
      localStorage.setItem('1_1',JSON.stringify(this.$store.state.list_doing))

    },
    get_titire(i,a){
      this.$store.state.nom_projet=i 
      this.$store.state.adding=true
      this.$store.state.modifieur[0]=true
      this.$store.state.modifieur[1]=a
    },
  },
  mounted(){

  }
}
</script>
<style scoped>
#point{
  display:solid;
  width: 3px;
  height: 3px;
  background:white;
  border-radius:2px;
}
</style>