<template>
  <home class=" overflow-y-hidden"></home>
</template>

<script>
import home from './views/Home.vue'
export default {
  components:{
    home
  },
  data(){
    return {
    }
  },
  methods:{

  },
  mounted(){
    // console.log(this.$store.state.count)
    if(localStorage.getItem('0_0') && localStorage.getItem('1_1')){

        this.$store.state.box_project=JSON.parse(localStorage.getItem('0_0'))
        this.$store.state.list_doing=JSON.parse(localStorage.getItem('1_1'))
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}


#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
