<template>
  <!-- trois point -->
  <div class="absolute top-0 right-2 flex flex-row-reverse justify-between w-full">
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
    <span v-if="this.$store.state.index==undefined" class="text-white">Vous avez {{this.$store.state.box_project.length>1 ?this.$store.state.box_project.length+' projets':this.$store.state.box_project.length==0?'Aucun projet':this.$store.state.box_project.length+' projet'}}</span>
    <div class="text-white font-bold ml-3"></div>
  </div>
  <div v-if="this.$store.state.index!=undefined" class=" z-10 absolute top-0 left-0 cursor-pointer" @click="retour()">
    <span class="text-white text-2xl px-3" v-text="'<'"></span>
  </div>

  <div class="flex bg-black  flex-col-reverse sm:flex-col w-full h-full">
    
    <!-- en tête -->
    <heade class=" bg-black w-full heade duration-700"></heade >

    <!-- si le tâche est vide -->
    <div v-if="this.$store.state.box_project==''" class="bg-black  overflow-y-hidden flex flex-col justify-center w-full h-full">
      <button @click="set_adding()" class="flex justify-center flex-col items-center">
        <span class="text-gray-600 text-xl">Nouveau projet</span>
        <svg xmlns="http://www.w3.org/2000/svg" height="44px" viewBox="0 0 24 24" width="44px" fill="#677"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
      </button>
    </div>

    <!-- si le tache n'est pas vide -->
    <div id="centrale-content" class="flex overflow-x-hidden w-full max-h-full overflow-y-auto mt-7 " :class="(this.$store.state.index==undefined)?'flex':'hidden'">
      <div v-if="this.$store.state.box_project!='' " class="p-1  py-3 bg-black grid grid-cols-3  w-full h-full"
      :class="(this.$store.state.box_project.length<10)?'grid-rows-3':''">
        <div v-for="box,i in this.$store.state.box_project " 
        :key="i" 
        class=" group flex flex-col justify-center items-center rounded-r-lg border-r-4 border cursor-pointer border-gray-600 hover:border-white w-24 h-28 mx-1 mb-6" 
        :class="(this.$store.state.box_project.length>9)?this.$store.state.box_project[i][1].length==0?'opacity-30 ':'opacity-100':this.$store.state.box_project[i][1].length==0?'opacity-30 mb-6':'opacity-100 mb-6'">
        <div class="flex w-full relative ">
          <div 
          :class="this.$store.state.box_project[i]?this.$store.state.box_project[i][1]?this.$store.state.box_project[i][1].length == total_faite(i) && this.$store.state.box_project[i][1].length>0?'flex':'hidden':'':''"
          class="flex absolute bg-black w-full h-20 justify-center cursor-wait items-center flex-col" @click="get_it(i)">
            <svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 0 24 24" width="34px" fill="#30F090"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>
            <span class="text-green-500 text-xs">{{box[0].length>12?box[0].substr(0,9)+'...':box[0]}}</span>
          </div>
        </div>
          <div class="flex w-full h-full justify-center  items-center flex-col"  @click="get_it(i)">
                <div class="relative w-full ">
                  <span class="text-white w-14 absolute -mt-11 ml-0 text-xs ">{{total_faite(i)+' / '+(this.$store.state.box_project[i][1].length)}}</span>
                </div>
              <span class="text-white text-sm">{{box[0].length>12?box[0].substr(0,9)+'...':box[0]}}</span>
          </div>
          
          <div class=" duration-500 group-hover:opacity-100 opacity-0 relative flex-row justify-end flex w-full ">
            <div class="  absolute  ml-3 flex flex-row -mt-7 ">
              <svg @click="get_titire(box[0],i)" class=" mr-2 animate-bounce" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
              <svg  @click="get_to_remove(box[0],i)"  class="" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="asking==true" class="flex w-full h-full absolute top-0 left-0 justify-center items-center">
        <div class="flex w-11/12 bg-gray-900 flex-col py-3 px-2">
          <div class="w-full mt-4 flex flex-col">
            <span class="text-gray-700 text-2xl">Supprimer !</span>
            <span class="text-white text-sm">{{this.texte.length>20?this.texte.substr(0,9)+'...':this.texte}}</span>
          </div>
          <div class="w-full flex mt-5 justify-end">
            <button @click="asking=false" class="px-6 text-green-500 mr-5">Non</button>
            <button @click="remove(i)" class="px-6 text-white bg-red-600 outline-none rounded-lg">Oui</button>
          </div>

        </div>
    </div>

    <taches :class="(this.$store.state.index!=undefined)?'flex':'hidden'"></taches>
    
  </div>
</template>

<script>
import heade from '../components/header.vue'
import taches from '../components/taches.vue'

export default {
  components: {
    heade,taches
  },
  data(){
    return{
      asking:false,
      texte:"",
      index:''
    }
  }
  ,
  methods:{
    set_adding(){
        this.$store.state.adding==false?this.$store.state.adding=true:this.$store.state.adding=false
      
    },
    get_it(i){
      this.$store.state.index=i
      this.$store.state.hide_tool=false
    },
    retour(){
      this.$store.state.index=undefined
      this.$store.state.hide_tool=true
    },
    get_titire(i,a){
      this.$store.state.nom_projet=i 
      this.$store.state.adding=true
      this.$store.state.modifieur[0]=true
      this.$store.state.modifieur[1]=a
    },

    total_faite(i){
      var array= this.$store.state.list_doing[i]
      var total=0
      if(array){

      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if(element==true){
          total++
        }
      }
      }
      return total
    },
    get_to_remove(i,a){
      this.texte=i
      this.asking=true
      this.index=a
    },
    remove(){
      var array=[]
      this.$store.state.box_project[this.index]=''
      
      for (let i = 0; i < this.$store.state.box_project.length; i++) {
        const element = this.$store.state.box_project[i];
        if(element!=''){
          array.push(element)
        }
      }
      this.$store.state.box_project=array

      var array2=[]
      this.$store.state.list_doing[this.index]=''
      
      for (let i = 0; i < this.$store.state.list_doing.length; i++) {
        const element = this.$store.state.list_doing[i];
        if(element!=''){
          array2.push(element)
        }
      }
      this.$store.state.list_doing=array2
      this.asking=false

      localStorage.setItem('0_0',JSON.stringify(this.$store.state.box_project))
      localStorage.setItem('1_1',JSON.stringify(this.$store.state.list_doing))
    }
  },
}
</script>
<style >
</style>
