import {createStore} from 'vuex'

export default createStore({
    state:{
        adding:false,
        box_project:[],
        box_project_temp:[],
        hide_tool:true,
        nom_projet:'',
        index:undefined,
        list_doing:[],
        faites:false,
        pasFaites:false,
        modifieur:[false,null]
    },
  })

