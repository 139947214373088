<template>
  <div class="flex z-10 border-gray-500 duration-700 justify-center py-3 px-2 w-full place-items-center" :class="(this.$store.state.adding==true)?'heade_up flex-col':'heade_down flex-col'" >
   <div class="flex flex-row w-full">
      <div  class=" border border-gray-600 sm:py-1 rounded-full px-2 sm:px-3 flex flex-row justify-between w-full md:w-4/12 sm:w-6/12 place-items-center py-2 ">
        <input 
         @keydown="key_down($event)"
         @keyup="verifier()"
        v-model="this.$store.state.nom_projet" 
        type="text" 
        name="" 
        id="" 
        :placeholder="(this.$store.state.adding==true )?(this.$store.state.hide_tool==true)?'Nom du projet':'Ajouter un nouveau taches':(this.$store.state.hide_tool==false)?'Recherche une tache':'Recherche '" 
        class=" bg-transparent duration-500 rounded-lg outline-none px-3 text-white w-full"
        >
        <svg v-if="this.$store.state.adding==false" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#677"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>

        <div class="flex flex-row" v-if="this.$store.state.adding!=false">
          <svg  @click="set_annuler()" class="" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="red"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
        </div>
      </div>

      <div v-if="this.$store.state.adding==false" @click="set_adding()" class=" border border-gray-600 py-2 rounded-full px-2 sm:px-3 flex ml-1 sm:ml-3 justify-center place-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#677"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
      </div>
      <div v-if="this.$store.state.adding==true" @click="add_projet()" class=" border border-gray-600 py-2 rounded-full px-2 sm:px-3 flex ml-1 sm:ml-3 justify-center place-items-center">
        <svg  v-if="this.$store.state.modifieur[0]==false" class="" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
        <svg v-if="this.$store.state.modifieur[0]==true"  xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="white"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/></svg>
      </div>
   </div>
   <!-- autre icon -->
   <div  :class="(this.$store.state.adding==true && this.$store.state.hide_tool==false)?'flex':'hidden'" class="flex flex-row w-full justify-start mt-3 items-center">
      <div @click="liste_afaires()" class="flex flex-row cursor-pointer px-1 py-1 bg-red-600 rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
      </div>
      <div @click="liste_faites()" class="flex flex-row cursor-pointer px-1 py-1 bg-green-500 rounded-full ml-2">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
      </div>
      <div v-if="this.$store.state.list_doing.length>0 &&   this.$store.state.box_project.length>0" class="flex w-full progress ml-1 rounded-full">
        <span v-if="this.$store.state.list_doing[this.$store.state.index]" class=" z-20 w-8/12 absolute text-white items-center">{{total_faite()+' sur '+(this.$store.state.box_project[this.$store.state.index][1].length)}}</span>
        <div id="inside_progress" class=" duration-500 relative inside_progress z-0 rounded-l-full"></div>
      </div>
   </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      pourcentage:0,
    }
  },
  methods:{
    set_adding(){
      this.$store.state.adding==false?this.$store.state.adding=true:this.$store.state.adding=false
      
    },
    set_annuler(){
      this.$store.state.adding==false?this.$store.state.adding=true:this.$store.state.adding=false
      this.$store.state.nom_projet=''
      this.$store.state.modifieur[0]=false
    },
    add_projet(){
      if(this.$store.state.nom_projet!=""){
        if(this.$store.state.index==undefined ){
          if(this.$store.state.modifieur[0]==true){
             this.$store.state.box_project[this.$store.state.modifieur[1]][0]=this.$store.state.nom_projet
          }else{
            this.$store.state.box_project.push([this.$store.state.nom_projet,[]])
            this.$store.state.list_doing.push([])
          }
          this.$store.state.adding=true
        }else{
          if(this.$store.state.modifieur[0]==true){
             this.$store.state.box_project[this.$store.state.index][1][this.$store.state.modifieur[1]][0]=this.$store.state.nom_projet
          }else{
          this.$store.state.box_project[this.$store.state.index][1].push([this.$store.state.nom_projet,false])
          this.$store.state.list_doing[this.$store.state.index].push(false)
          }

          // 
          this.set_precent()
          this.$store.state.adding=true
        }
        this.$store.state.modifieur[0]=false
        this.$store.state.nom_projet=''
        //sauvegarde dans localstorage
        localStorage.setItem('0_0',JSON.stringify(this.$store.state.box_project))
        localStorage.setItem('1_1',JSON.stringify(this.$store.state.list_doing))
        
        setTimeout(() => {
          document.querySelector('#centrale-content').scrollTop=document.querySelector('#centrale-content').scrollHeight
        }, 500);
      
      }
    },
    set_precent(){
      
      this.pourcentage=this.percentage(this.$store.state.box_project[this.$store.state.index][1])
      if(this.pourcentage==100){
        document.querySelector('#inside_progress').style.borderRadius='50px 50px 50px 50px'  
      }
      else{
        document.querySelector('#inside_progress').style.borderRadius='50px 0px 0px 50px'  
      }
      document.querySelector('#inside_progress').style.width=this.pourcentage+'%'
    },
    percentage(somme){
      return this.total_faite()*100/somme.length
    },
    liste_faites(){
      this.$store.state.faites=true
    },
    liste_afaires(){
      this.$store.state.faites=false
    },
    total_faite(){
      var array= this.$store.state.list_doing[this.$store.state.index]
      var total=0
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if(element==true){
          total++
        }
        
      }
      return total
    },
    key_down(e){
      if(e.keyCode==13){
        this.add_projet()
      }  
    },
    search_module(e){
      var array=[]
      // console.log(this.$store.state.box_project[this.$store.state.index][1])
      for (let i = 0; i < this.$store.state.box_project[this.$store.state.index][1].length; i++) {
        const element = this.$store.state.box_project[this.$store.state.index][1][i][0];
        if(element.includes(e)){
          array.push(this.$store.state.box_project[this.$store.state.index][1][i])
        }       
      }
      this.$store.state.box_project_temp=array
    },
    verifier(){
      if(this.$store.state.nom_projet==''){
        this.$store.state.box_project_temp=[]
      }else{
        this.$store.state.index==undefined?'': this.search_module(this.$store.state.nom_projet)
      }
    }
  },
}

</script>
<style scoped>
.progress{
  height: 20px;
  border:solid 1px gray;

}
.inside_progress{
  height: 19px;
  width:20px;
  background: gray;
}

</style>
